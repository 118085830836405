import * as React from 'react'
import Img from "gatsby-image"
import styled from '@emotion/styled'

import Container from '../../components/Container'
import { Styledh4, Styledh2, Styledp, StyledContainer, colors } from '../../styles/cards'

export const CustomImgWrapper = styled.div`
  text-align: center;
`

export const CustomImg = styled.img`
  width: 85%;
`

export const CustomStyledp = styled(Styledp)`
  font-size: 1.1rem;
  margin-top: 0.75em;
  text-align: center;
  line-height: 1.5;
`

interface ContainerProps {
  photo: any;
  desktop: any;
  eyecatch: any;
  containerHeight: string;
}

const EyeCatch: React.FC<ContainerProps> = ({ photo, eyecatch, desktop, containerHeight }) => {

  return (
    <Container height={containerHeight}>
      <Img sizes={photo.node.childImageSharp.sizes} />
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.eyecatch}>
        <Styledh4>Theme</Styledh4>
        <CustomImgWrapper><CustomImg src="../../../eyecatch/0001.jpg" /></CustomImgWrapper>
        <CustomStyledp>
          旅行と旅の、いいとこどり。<br />
          みんなでつくる唯一無二の淡路島。<br />
          ちょっと特別な招待状です。
        </CustomStyledp>
      </StyledContainer>
    </Container>
  )
}

export default EyeCatch
