import * as React from 'react'
import Img from "gatsby-image"

import Container from '../Container'
import { Styledh4, Styledp, StyledContainer, colors } from '../../styles/cards'


interface ContainerProps {
  photo: any;
  desktop: any;
  containerHeight: string;
}

const About: React.FC<ContainerProps> = ({ photo, desktop, containerHeight }) => {

  return (
    <Container height={containerHeight}>
      <Img sizes={photo.node.childImageSharp.sizes} />
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.concept}>
        <Styledh4>About</Styledh4>
        <Styledp>
          「こんな旅行、ちょっとない。」<br />
          こんにちは、淡路グルーヴ運営です。<br />
          <br />
          急にお誘いの連絡が来たけれど、何の企画なの？なんで淡路島なの？わからないこと多すぎぃいい！<br />
          という声にお応えして、なんとこの度、開催背景から思いの丈まで、１つのnoteにまとめさせていただきました！<br />
          <br />
          <a href="https://note.mu/awaji_groooove/n/n6eba171c16a7">
            運営からのご挨拶
          </a><br />
          <br />
          とりあえず、中々ちょっとない、だいぶ凝ってる遊びということです。<br />
          それでは、淡路にて。<br />
        </Styledp>
        {/* <Styledp>
          「こんな旅行、ちょっとない。」<br />
          こんにちは！<br />
          <a href="https://note.mu/preview/nf83657538399?prev_access_key=7162f19fdaf977451dc3ff0e33e410e8">
            運営からのご挨拶
          </a>をまとめました。<br />
          ぜひ、みてくれよな！<br />
          淡路島でお待ちしております。
        </Styledp> */}
        {/* <Link to="/page-2/">Go to page 2</Link> */}
      </StyledContainer>
    </Container>
  )
}

export default About
