import * as React from 'react'
import Img from "gatsby-image"
import styled from '@emotion/styled'

import Container from '../Container'
import { Styledh4, Styledp, StyledContainer, colors } from '../../styles/cards'

const NewStyledp = styled(Styledp)`
  line-height: 1.4;
`

const A = styled.a`
  color: #ffffff;
`

interface ContainerProps {
  photo: any;
  desktop: any;
  containerHeight: string;
}

const Contact: React.FC<ContainerProps> = ({ photo, desktop, containerHeight }) => {

  return (
    <Container height={containerHeight}>
      <Img sizes={photo.node.childImageSharp.sizes} />
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.contact}>
        <Styledh4>Contact</Styledh4>
        <NewStyledp>
          運営の公式LINEアカウントをつくりました！<br />
          こちらで諸々連絡をします。<br />
          ・思わず淡路島に行きたくなった方<br />
          ・まだ予定がわからず迷っている方<br />
          一旦友達登録おねがいします！<br />
        </NewStyledp>
        <NewStyledp><A href="http://nav.cx/cHGo9D8">LINEアカウントはこちら</A></NewStyledp>
        <NewStyledp>
          質問などもこちらのアカウントで随時受付中です。<br />
          また、もっと雰囲気を知りたい！<br />
          という方はぜひ <A href="https://koyano-camp-2018.com"> 淡路2018 </A>もご参考ください。
        </NewStyledp>
      </StyledContainer>
    </Container>
  )
}

export default Contact
