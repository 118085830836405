import * as React from 'react'
import Img from "gatsby-image"
import styled from '@emotion/styled'

import Container from '../../components/Container'
import { Styledh4, Styledp, StyledContainer, colors } from '../../styles/cards'

const NewStyledp = styled(Styledp)`
  line-height: 1.3;
`

interface ContainerProps {
  photo: any;
  desktop: any;
  containerHeight: string;
}

const Location: React.FC<ContainerProps> = ({ photo, desktop, containerHeight }) => {

  return (
    <Container height={containerHeight}>
      <Img sizes={photo.node.childImageSharp.sizes} />
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.location}>
        <Styledh4>Location</Styledh4>
        <NewStyledp><a href="http://zionfarm.jp/camp/">小さな丘の上の菜音キャンプ</a></NewStyledp>
        <NewStyledp>
          <br />
          今回、とっておきの会場をブッキングしました！<br />
          カフェも併設した素敵なキャンプ場です。
        </NewStyledp>
        <NewStyledp>
          宿泊：コテージ・トレーラーハウス・テント<br />
          遊具：焚き火台・ハンモック・トランポリン<br />
          <br />
          お金：飲食費込みで12,000円前後の想定です。<br />
          正しい金額については、追って連絡します。<br />
          <br />
        </NewStyledp>
        <NewStyledp><a href="https://www.instagram.com/explore/tags/%E8%8F%9C%E9%9F%B3%E3%82%AD%E3%83%A3%E3%83%B3%E3%83%97/">会場の雰囲気はこちら</a></NewStyledp>
      </StyledContainer>
    </Container>
  )
}

export default Location
