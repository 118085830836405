import * as React from 'react'
import BackgroundImage from 'gatsby-background-image'
import styled from '@emotion/styled'

import Container from '../../components/Container'
import { Styledh4, Styledh2, Styledp } from '../../styles/cards'

const StyledContainer = styled.div`
  user-select: auto;
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
  white-space: normal;
  display: flex;
  flex-direction: column;
`

const ColumnContainer = styled.div`
  background-position: top;
  background-color: #${(props: ImageProps) => props.color};
`

type ImageProps = {
  color: string
}

const Column1 = styled(BackgroundImage)`
  background-position: top;
  background-repeat: repeat-x;
  padding: 2rem 1rem 1rem 1rem;
  flex: 1;
  background-size: 73% 100%;
`

const Column2 = styled(BackgroundImage)`
  background-position: top;
  background-repeat: repeat-x;
  padding: 2rem 1rem 1rem 1rem;
  flex: 1;
  background-size: 73% 100%;

`

const Column3 = styled(BackgroundImage)`
  background-position: top;
  background-repeat: repeat-x;
  padding: 2rem 1rem 1rem 1rem;
  flex: 1;
  background-size: 73% 100%;
`

const NewStyledh2 = styled(Styledh2)`
  font-family: 'Jockey One', sans-serif;
`

const NewStyledp = styled(Styledp)`
  color: #${(props: ImageProps) => props.color};
  margin-top: 2rem;
`

interface ContainerProps {
  photos: any;
  containerHeight: string;
}

const Member: React.FC<ContainerProps> = ({ photos, containerHeight }) => {
  return (
    <Container height={containerHeight}>
      <StyledContainer>
        <ColumnContainer color={'84d0c5'}>
          <Column1 classId={'ColumnContainer-0'} Tag="section" fluid={photos.edges[0].node.childImageSharp.fluid}>
            <NewStyledh2>YUJI KOYANO</NewStyledh2>
            <NewStyledp color={'FFFFFF'}>
              しごと：ソフトウェア屋<br />
              しゅみ：いきなりカレーとプログラミング<br />
              すみか：恵比寿の喫茶<br />
              やくわり：オーガナイズと制作<br />
              淡路島で人生が変わったひと。<br />
            </NewStyledp>
          </Column1>
        </ColumnContainer>
        <ColumnContainer color={'fecb20'}>
          <Column2 classId={'ColumnContainer-1'} Tag="section" fluid={photos.edges[1].node.childImageSharp.fluid}>
            <NewStyledh2>TOMOAKI HOSHINO</NewStyledh2>
            <NewStyledp color={'000000'}>
              しごと：広告屋<br />
              しゅみ：妄想とブルース<br />
              すみか：各所のサウナ<br />
              やくわり：コンセプトメイクとPR<br />
              淡路島で人生が変わったコヤノの相棒。<br />
            </NewStyledp>
          </Column2>
        </ColumnContainer>
        <ColumnContainer color={'cbd37a'}>
          <Column3 classId={'ColumnContainer-2'} Tag="section" fluid={photos.edges[2].node.childImageSharp.fluid}>
            <NewStyledh2>YUMA KUTSUZAWA</NewStyledh2>
            <NewStyledp color={'FFFFFF'}>
              しごと：プラント保全屋<br />
              しゅみ：フィルムカメラと稲作<br />
              すみか：飛行機のなか(出張)<br />
              やくわり：アイデアと渉外<br />
              コヤノとホシノに淡路を教えたひと。<br />
            </NewStyledp>
          </Column3>
        </ColumnContainer>
        {/* <Link to="/page-2/">Go to page 2</Link> */}
      </StyledContainer>
    </Container>
  )
}

export default Member
