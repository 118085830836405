import styled from '@emotion/styled'
import BackgroundImage from 'gatsby-background-image'

export const Styledh4 = styled.h4`
  display: block;
  width: 100%;
  text-transform: uppercase;
  border-color: #210604;
  font-size: 13px;
  line-height: 1.2;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  margin-bottom: 1.5em;
  font-weight: 700;
  line-height: 1 !important;
  padding-bottom: 0.6em;
  margin-top: 0;
  border-bottom: 2px solid;
  // font-family: ff-more-web-pro, serif;
  font-family: 'Jockey One', sans-serif;
  // font-family: 'Lobster', cursive;
`

export const Styledh2 = styled.h2`
  font-size: 1rem;
  line-height: 1.1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: #000000;
  font-family: ff-more-web-pro, serif;
  // font-family: 'Lobster', cursive;
`

export const Styledp = styled.p`
  font-size: 0.95rem;
  line-height: 1.2;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  color: #000000;
  font-family: 'Noto Sans JP', sans-serif;
`

type ImageProps = {
  color: string
}

export const StyledContainer = styled(BackgroundImage)`
  background-color: #${(props: ImageProps) => props.color};
  user-select: auto;
  position: relative;
  z-index: 0;
  // display: table-cell;
  width: 100%;
  height: 100%;
  white-space: normal;
  padding: 4vw 4vw;
`
export const colors = {
  eyecatch: '84d0c4',
  content: 'cbd37a',
  concept: 'fecb20',
  member: 'df5049',
  location: 'd8d7db',
  contact: 'df5049',
  qa: '4e444b'
}

export const cardColors = {
  old: 'A7544D',
  wasure: 'D6C45C',
  anoko: 'A6C461',
  mirai: '549488',
}
