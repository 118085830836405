import * as React from 'react'
import Img from "gatsby-image"
import styled from '@emotion/styled'

import Container from '../../components/Container'
import { Styledh4, Styledh2, Styledp, StyledContainer, colors } from '../../styles/cards'

export const Icon = styled.div`
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 20px;
`

interface ContainerProps {
  photo: any;
  desktop: any;
  icon1: any;
  icon2: any;
  containerHeight: string;
}

const Content: React.FC<ContainerProps> = ({ photo, desktop, icon1, icon2, containerHeight }) => {

  return (
    <Container height={containerHeight}>
      <Img sizes={photo.node.childImageSharp.sizes} />
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.content}>
        <Styledh4>Schedule</Styledh4>
        <Styledh2>9/21(Sat.) AM  グループで駅に集合</Styledh2>
        <Styledp>
          神戸駅に着いたら、気のしれた仲間達と<br />
          淡路島までの楽しい観光ドライブ。<br />
          しかしその道中で待っているものは<br />
          ただのドライブじゃなくて・・・？？？
          </Styledp>
        {/* <Icon>
          <Img sizes={icon1.node.childImageSharp.sizes} />
        </Icon> */}
        <Styledh2>9/21(Sat.) PM  キャンプ場にみんなで集合</Styledh2>
        <Styledp>
          会場に着いたら、楽しいパーティーの始まり。<br />
          美味しい料理とお酒に酔いしれるもよし。<br />
          焚き火にあたりながらかたらうもよし。
          </Styledp>
        {/* <Icon>
          <Img sizes={icon2.node.childImageSharp.sizes} />
        </Icon> */}
      </StyledContainer>
    </Container>
  )
}

export default Content
