import * as React from 'react'
import styled from '@emotion/styled'

import Container from '../../components/Container'
import { Styledh4, Styledp, StyledContainer, colors } from '../../styles/cards'


interface ContainerProps {
  desktop: any;
  containerHeight: string
}

export const CustomStyledh4 = styled(Styledh4)`
  color: #FFFFFF;
  // margin-bottom: 1em;
`

export const Q = styled.span`
  font-size: 1rem;
  margin-bottom: 0.5em;
  color: #FFFFFF;
  border-bottom: solid 0.5px #FFFFFF;
`
export const A = styled(Styledp)`
  font-size: 0.9rem;
  line-height: 1.4;
  // font-size: 0.85rem;
  // line-height: 1.2;
  color: #FFFFFF;
`

const QA2: React.FC<ContainerProps> = ({ desktop, containerHeight }) => {
  return (
    <Container height={containerHeight}>
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.qa}>
        <Q>Q.他に誰が来るんですか？</Q>
        <A>
          申し訳ございません、詳細は明かすことができません。
          ただ、運営メンバーを中心とした「友達の友達」までしか招待しておりません。
        </A>

        <Q>Q.神戸や四国など、他の場所も観光したいです。</Q>
        <A>
          当日に関しては、各コミュニティごとに運営から旅程の連絡があります。
          3連休のため、企画解散後に各観光地をご旅行いただくことが例年多いです。
        </A>

        <Q>Q.知り合いが少なそうです。どうしたらいいですか？</Q>
        <A>
          心配ないです。皆さん、アウェーなのかホームなのかわからずに来ます。
          そして、そのくらいがちょうどいい空気感を作ることを目標としております。
        </A>
        <br />
        <Q>Q.参加する価値は本当にありますか？</Q>
        <A>
          はい。後悔はさせません。
        </A>
      </StyledContainer>
    </Container>
  )
}

export default QA2
