import * as React from 'react'
import styled from '@emotion/styled'

import Container from '../../components/Container'
import { Styledh4, Styledp, StyledContainer, colors } from '../../styles/cards'


interface ContainerProps {
  desktop: any;
  containerHeight: string
}

export const CustomStyledh4 = styled(Styledh4)`
  color: #FFFFFF;
  // margin-bottom: 1em;
`

export const Q = styled.span`
  font-size: 0.95rem;
  margin-top: 0.2em;
  margin-bottom: 0.5em;
  color: #FFFFFF;
  border-bottom: solid 0.5px #FFFFFF;
`
export const A = styled(Styledp)`
  font-size: 0.9rem;
  line-height: 1.4;
  color: #FFFFFF;
`

const QA: React.FC<ContainerProps> = ({ desktop, containerHeight }) => {
  return (
    <Container height={containerHeight}>
      <StyledContainer Tag="section" fluid={desktop.childImageSharp.fluid} color={colors.qa}>
        <CustomStyledh4>QA</CustomStyledh4>
        {/* <Q>Q.交通手段は何ですか？</Q>
        <A>
          関東からお越しの方：新幹線OR飛行機<br />
          関西からお越しの方：電車OR車<br />
          交通チケットに関しては別途連絡しますが、各コミュニティ班で各々確保いただく形となります。<br />
        </A> */}

        <Q>Q. キャンプ場への交通手段は何ですか？</Q>
        <A>
          各コミュニティ班の集合場所（神戸）までは各自で来ていただきます。
          集合場所からはレンタカーでキャンプ場まで来ていただきます。
          </A>

        <Q>Q.当日のスケジュールを教えてください。</Q>
        <A>
          申し訳ございません、詳細は明かすことができません。
          大まかに、当日お昼前には神戸着、夜前には会場に到着いただきます。
        </A>

        <Q>Q.他に誰が来るんですか？</Q>
        <A>
          申し訳ございません、詳細は明かすことができません。
          ただ、運営メンバーを中心とした「友達の友達」までしか招待しておりません。
        </A>

        <Q>Q.神戸や四国など、他の場所も観光したいです。</Q>
        <A>
          当日に関しては、各コミュニティごとに運営から旅程の連絡があります。
          3連休のため、企画解散後に各観光地をご旅行いただくことが例年多いです。
        </A>

        <Q>Q.知り合いが少なそうです。どうしたらいいですか？</Q>
        <A>
          心配ないです。皆さん、アウェーなのかホームなのかわからずに来ます。
          そして、そのくらいがちょうどいい空気感を作ることを目標としております。
        </A>
        <Q>Q.参加する価値は本当にありますか？</Q>
        <A>
          はい。後悔はさせません。
        </A>
      </StyledContainer>
    </Container>
  )
}

export default QA
