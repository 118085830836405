import * as React from 'react'
import { graphql } from 'gatsby'
const { useState, useEffect } = React;
import { Element, scroller } from 'react-scroll'
import { isChrome, isSafari, isMobileSafari, isMobile } from "react-device-detect";
import WebFont from "webfontloader";

import Page from '../components/Page'
import IndexLayout from '../layouts'
import { EyeCatch, Concept, Member, Location, Content, Contact, QA, QA2 } from '../components/cards/index'

interface ContainerProps {
  data: {
    photos: any;
    desktop: any;
    eyecatch: any;
    members: any;
    content: any;
  }
}

enum IPhoneType {
  IPOHNE_X,
  IPOHNE_6_PLUS,
  IPOHNE_6,
  IPOHNE_5,
  NOT_IPOHNE,
}

function getiPhoneModel() {
  // iPhone X
  if ((window.screen.height / window.screen.width == 812 / 375) && (window.devicePixelRatio == 3)) {
    return IPhoneType.IPOHNE_X;
  }

  // iPhone 6+/6s+/7+ and 8+
  else if ((window.screen.height / window.screen.width == 736 / 414) && (window.devicePixelRatio == 3)) {
    return IPhoneType.IPOHNE_6_PLUS;
  }

  // iPhone 6/6s/7 and 8
  else if ((window.screen.height / window.screen.width == 667 / 375) && (window.devicePixelRatio == 2)) {
    return IPhoneType.IPOHNE_6;
  }

  // iPhone 5/5C/5s/SE or 6/6s/7 and 8 in zoom mode
  else if ((window.screen.height / window.screen.width == 1.775) && (window.devicePixelRatio == 2)) {
    return IPhoneType.IPOHNE_5;
  }

  return IPhoneType.NOT_IPOHNE;
}

const IndexPage: React.FC<ContainerProps> = ({ data }) => {
  const [index, setIndex] = useState(0);
  const [iPhoneType, setIPhoneType] = useState(IPhoneType.NOT_IPOHNE);

  useEffect(() => {
    WebFont.load({
      typekit: {
        id: 'vsu8loo'
      }
    });
  });

  useEffect(() => {
    if (!isMobile) {
      window.location.replace("https://google.com")
    }
  });

  useEffect(() => {
    setIPhoneType(getiPhoneModel())
  });

  useEffect(() => {
    if (iPhoneType !== IPhoneType.IPOHNE_6) return
    if ((isChrome || isSafari || isMobileSafari)) return

    var userAgent = window.navigator.userAgent.toLowerCase(),
      safari = /safari/.test(userAgent),
      ios = /iphone|ipod|ipad/.test(userAgent);

    if (ios) {
      if (safari) {
        //browser
      } else if (!safari) {
        //webview
        return
      };
    } else {
      //not iOS
    };

    var touchStartY: number;
    var touchMoveY: number;

    function touchstart(event: any) {
      // event.preventDefault();
      touchStartY = event.touches[0].pageY;
    }

    function touchmove(event: any) {
      event.preventDefault();
      touchMoveY = event.changedTouches[0].pageY;
    }

    function touchend(event: any) {
      if (touchStartY > touchMoveY + 6 && index < 7) {
        scroller.scrollTo(`scroll-to-element-${index + 1}`, {
          duration: 200,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
        setIndex(index + 1)
      } else if (touchStartY + 5 < touchMoveY && 0 < index) {
        scroller.scrollTo(`scroll-to-element-${index - 1}`, {
          duration: 200,
          delay: 0,
          smooth: 'easeInOutQuart'
        })
        setIndex(index - 1)
      }
    }

    document.addEventListener("touchstart", touchstart, { passive: false });
    document.addEventListener("touchmove", touchmove, { passive: false });
    document.addEventListener("touchend", touchend);

    return () => {
      document.removeEventListener("touchstart", touchstart, false);
      document.removeEventListener("touchmove", touchmove, false);
      document.removeEventListener("touchend", touchend);
    };
  }, [index, iPhoneType]);

  const convertContainerHeight = (iPhoneType: IPhoneType): string => {
    // if(isChrome) return '100vh'
    switch (iPhoneType) {
      case IPhoneType.NOT_IPOHNE:
        return 'auto'
      case IPhoneType.IPOHNE_5:
        return 'auto'
      case IPhoneType.IPOHNE_X:
        return 'auto'
      case IPhoneType.IPOHNE_6:
        return '100vh'
      case IPhoneType.IPOHNE_6_PLUS:
        return 'auto'
      default:
        return 'auto'
    }
  }

  return (
    <IndexLayout>
      <Page>
        {/* アイキャッチ */}
        <Element name={`scroll-to-element-0`} className='element' key={`element-0`}>
          <EyeCatch
            containerHeight={convertContainerHeight(iPhoneType)}
            photo={data.photos.edges[0]}
            eyecatch={data.eyecatch.edges[0]}
            desktop={data.desktop} />
        </Element>

        {/* コンテンツ */}
        <Element name={`scroll-to-element-1`} className='element' key={`element-1`}>
          <Content
            containerHeight={convertContainerHeight(iPhoneType)}
            photo={data.photos.edges[1]}
            desktop={data.desktop}
            icon1={data.content.edges[0]}
            icon2={data.content.edges[1]}
          />
        </Element>

        {/* About */}
        <Element name={`scroll-to-element-2`} className='element' key={`element-2`}>
          <Concept
            containerHeight={convertContainerHeight(iPhoneType)}
            photo={data.photos.edges[2]}
            desktop={data.desktop} />
        </Element>

        {/* メンバー */}
        <Element name={`scroll-to-element-3`} className='element' key={`element-3`}>
          <Member
            containerHeight={convertContainerHeight(iPhoneType)}
            photos={data.members} />
        </Element>

        {/* 会場情報 */}
        <Element name={`scroll-to-element-4`} className='element' key={`element-4`}>
          <Location
            containerHeight={convertContainerHeight(iPhoneType)}
            photo={data.photos.edges[3]}
            desktop={data.desktop} />
        </Element>

        {/* LINE */}
        <Element name={`scroll-to-element-5`} className='element' key={`element-5`}        >
          <Contact
            containerHeight={convertContainerHeight(iPhoneType)}
            photo={data.photos.edges[4]}
            desktop={data.desktop} />
        </Element>

        {/* Q&A */}
        <Element name={`scroll-to-element-6`} className='element' key={`element-6`}        >
          <QA
            containerHeight={convertContainerHeight(iPhoneType)}
            desktop={data.desktop} />
        </Element>

        {/* <Element name={`scroll-to-element-7`} className='element' key={`element-7`}        >
          <QA2
            containerHeight={convertContainerHeight(iPhoneType)}
            desktop={data.desktop} />
        </Element> */}
      </Page>
    </IndexLayout>
  )
}

export default IndexPage

/* eslint no-undef: off */
export const pageQuery = graphql`
  query HomeQuery {
    desktop: file(relativePath: {eq: "bg/bkg-noise.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 4160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    eyecatch: allFile(filter: {relativeDirectory: {eq: "eyecatch" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }

    members: allFile(filter: {relativeDirectory: {eq: "members" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }

    photos: allFile(filter: {relativeDirectory: {eq: "photos" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }

    content: allFile(filter: {relativeDirectory: {eq: "content" } }, sort: {order: ASC, fields: [name] }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 400) {
              ...GatsbyImageSharpSizes_tracedSVG
            }
          }
        }
      }
    }
}
`; ``
